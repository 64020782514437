import { Container, Row, Col, Image, Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap';

const Footer = () => {
  return (
    <>
      <footer className='d-flex flex-wrap align-items-center justify-content-center justify-content-md-between'>
        <Container>
          <Row>
            <Col xs={3} sm={2}></Col>
            <Col xs={2} sm={2} className="social-icons"><a href="https://twitter.com/pepetardiobase" target="_blank" rel="noreferrer"><img alt="twitter" src="X.png"></img></a></Col>
            <Col xs={2} sm={2} className="social-icons"><a href="https://t.me/PepetardioBase" target="_blank" rel="noreferrer"><img alt="telegram" src="tele.png"></img></a></Col>
            <Col xs={2} sm={2} className="social-icons"><a href="https://dexscreener.com/base/0x6735101f7bbc41e22a00a54a713d33399c303516" target="_blank" rel="noreferrer"><img alt="dexscreener" src="dex.png"></img></a></Col>
            <Col xs={3} sm={3}></Col>
          </Row>
        </Container>

      </footer>
    </>
  )
}

export default Footer
