import { Container, Row, Col, Image, Nav, Modal } from 'react-bootstrap'
import 'react-vertical-timeline-component/style.min.css';
import { LinkContainer } from 'react-router-bootstrap';
import styled from "styled-components";
import { useState } from "react"
import Masonry from 'react-masonry-css';

export const StyledButton = styled.button`
background-color: #000000;
border: 1px solid transparent;
color: #ffffff;
padding: 0.7rem 2rem;
letter-spacing: 2px;
border-radius: 0;
font-family: Poppins, Roboto, sans-serif;
font-weight: 600;
font-size: 1.2rem;
margin-top: 30px;
  :active {
    
  }
  :hover {
    border: 1px solid #f5aa00;
    color:#f5aa00;
    transition: linear 0.2s;
  }
  :disabled {
    opacity: 0.4
  }
`;

export const StyledRoundButton = styled.button`
background-color: transparent;
border-color: #f5aa00;
width: 34px;
height: 34px;
box-shadow: none;
border-radius: 2px;
font-size: 1rem;
border-style: solid;
color: #f5aa00;
border-width: 1px;
font-family: Poppins, Roboto, sans-serif;

:hover {
  border-color: #f5aa00;
  background-color: #f5aa00;
  color: #ffffff;
  transition: linear 0.2s;
}
`;

const Home = () => {

    const [images] = useState(Array.from({ length: 27 }, (_, i) => {
      if (i + 1 === 3) {
      return `gif.gif`;
      }
      return `${i + 1}.png`}));
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
  
    const handleImageClick = (image) => {
      setSelectedImage(image);
      setShowModal(true);
    };
  
    const handleClose = () => setShowModal(false);

      const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    900: 2,
    500: 1,
  };

  return (
    <div>
      <div class="header-bg desktop-only">
          {/* <Container> */}
      <header className='d-flex flex-wrap align-items-center justify-content-center justify-content-md-between'>
        <LinkContainer to='/'>
          <Nav.Link className='d-flex align-items-center col-md-1 mb-2 mb-md-0 text-decoration-none heading'>
            <img class="logo" alt="PEPETARDIO"src="logo.png"/>
          </Nav.Link>
        </LinkContainer>

        <Nav>
          <div className='text-end'>
          <Col xs={2} sm={2} className="social-icons"><a href="https://twitter.com/pepetardiobase" target="_blank" rel="noreferrer">
            <img alt="twitter" src="X.png"></img></a></Col>
            <Col xs={2} sm={2} className="social-icons"><a href="https://t.me/PepetardioBase" target="_blank" rel="noreferrer"><img alt="telegram" src="tele.png"></img></a></Col>
          <Col xs={2} sm={2} className="social-icons"><a href="https://dexscreener.com/base/0x6735101f7bbc41e22a00a54a713d33399c303516" target="_blank" rel="noreferrer"><img alt="dexscreener" src="dex.png"></img></a></Col>
          
          </div>
        </Nav>
      </header>
    {/* </Container> */}
    </div>
    <div className="mobile-only head">
      <Image src="logo.png" className="mobile-logo" alt="PEPETARDIO"/>
    </div>
    <Container className="main" id="meet">
    <Row>
      <h1>Meet <span className="purple">PEPETARDIO</span></h1>
            <Col className="center" xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
            <p className="pepetardio-quote marg-top" id="roast">The most retardio meme ever created is now on Base.<br/><br/>Follow <a href="https://twitter.com/pepetardiobase" target="_blank" rel="noreferrer">PEPETARDIO</a> on X for the most based posts.<br/><br/>Tag us for based replies from PEPETARDIO reply bot!</p>
            </Col>

            <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }} className="demo">
            <div class="flip-container desktop-only">
        <div class="card" id="card">
            <div id="front" class="front">

            </div>
            <div id="back" class="back">

            </div>
        </div>
    </div>
    <Image src="pepetardio.jpeg" alt="pepetardio" className="mobile-pic mobile-only"/>

</Col>
          </Row>


    </Container>


        <div class="about-section" id="about">
          <Container>
            <Row className="about-row">
              <Col xs={{ span: 12, order: 1 }} md={{ span: 12, order: 1 }} >
                <h2>About</h2>
                <p>Before remilia and before retardio there was Matt Furie. The creater of PEPE, the frog that grew up on 4CHAN and took the web by storm.<br/><br/>PEPETARDIO brings back the old PEPE that the internet fell in love with.<br/><br/>Utilizing the X platform and crypto PEPETARDIO will never be censored again. His memes and ideologies will live forever!</p>
              </Col>
            </Row>
          </Container>
        </div>
        <div class="buy-section" id="memes">
        <Container className="buy-row">
      <Row>
        <Col xs={12} md={12}>
          <h2>Meme Library</h2>
        </Col>
      </Row>
<Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {images.map((image, index) => (
          <div key={index} onClick={() => handleImageClick(image)}>
            <Image src={image} thumbnail />
          </div>
        ))}
      </Masonry>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Body closeButton>
          <div className="close" onClick={handleClose}>X</div>
          <Image src={selectedImage} fluid />
        </Modal.Body>
      </Modal>
    </Container>
        </div>
    </div>
  )
}

export default Home